import { storeToRefs } from 'pinia'
import { useCompaniesStore } from '~/stores/companies'
import { until } from '@vueuse/core'

export default defineNuxtRouteMiddleware(async ({ params }) => {
  const { companiesById, loadingStatus } = storeToRefs(useCompaniesStore())

  // wait for fetching companies as we need to check whether
  // the desination company is accessible by current user
  await until(loadingStatus).toBe(false, { timeout: 10000 })

  // TODO: Use casl to check
  if (!companiesById.value[params.companyId as string]) {
    return abortNavigation(
      createError({
        statusCode: 404,
        statusMessage: 'Not found',
      }),
    )
  }
})
